.csp-about-wrapper {
  width: 100%;
  margin: 0 35% 15px;

  .about-segment {
    background-color: #2d2e30 !important;
    color: #8a8e94 !important;

    background-size: auto 100% !important;
    background-blend-mode: color-dodge;
    background-repeat: no-repeat, no-repeat !important;
    background-position: bottom 2px right -4% !important;
  }

  .written-by {
    text-align: center;
  }

  .contacts-list {
    margin-left: 17px !important;
  }

  .div-contacts {
    text-align: center;
  }

  .link-discord {
    text-decoration: underline;
  }
}