html,
body {
    /*width: 100%;*/
    /*height: 100%;*/
    /*margin: 0px;*/
    /*padding: 0px;*/
    /*overflow-x: hidden;*/
    height: 100%;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    /*background-color: #282c34;*/
}

#root {
    /*height: 100%;*/
    /*height: 100vh;*/
    background-color: #202225;
}
