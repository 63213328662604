.ladder-top-wrapper {

  .segment-a {
    background-color: #2d2e30 !important;
    border: 1px solid #922c2c !important;
  }

  .segment-b {
    background-color: #2d2e30 !important;
    border: 1px solid #922c2c !important;
  }

  .segment-c {
    background-color: #2d2e30 !important;
    border: 1px solid #922c2c !important;
  }

  .segment-d {
    background-color: #2d2e30 !important;
    border: 1px solid #922c2c !important;
  }

  .segment-e {
    background-color: #2d2e30 !important;
    border: 1px solid #922c2c !important;
  }
}