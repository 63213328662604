.csp-stats-player {
  width: 100%;
  margin: 0 5% 15px;

  .segment {
    background-color: #2d2e30 !important;
    color: white;
  }

  .player-header-segment {

    .player-header {
      margin-top: 0;

      .week-number {
        color: #6dbf2f;
      }

      span img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
      }

      .span-name {
        padding-left: 5px;
      }
    }
  }

  table {
    th {
      background-color: #155b80 !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }

    tr {
      background-color: #202225;
      color: #8a8e94;
    }
  }

  .ssf-team-stats {
    th {
      //padding-top: 5px !important;
      //padding-bottom: 5px !important;
    }

    tr {
    }
  }

  .ssf-names {
    th {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }

    tr {
    }
  }

  .ssf-accuracy {
    .ssf-item-image {
      width: 30px !important;
      height: 30px !important;
      border-radius: 8px;
    }

    .with-image {
      width: 50px !important;
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
  }

  .overall-stats-button {
    position: absolute;
    right: 15px;
    vertical-align: center;
  }
}