.csp-historical-online-wrapper {
  width: 100%;

  .grid-wrapper {
    margin: 0 10px 15px;

    .menu-segment {
      background-color: #353e40;
      //min-width: 150px;
    }

    .menu-servers {
      width: 100% !important;
      margin-left: 1px !important;

      .active.item {
        background-color: #347b4f !important;
      }
    }
  }
}