.ladder-wrapper {
  width: 100%;
  margin: 0 2% 15px;

  .ladder-segment {
    background-color: #2d2e30 !important;

    .ladder-header {
      .sub-finished {
        color: #8a8e94 !important;
      }

      .sub-live {
        color: #c6450a !important;
      }

      .week-number {
        color: #6dbf2f;
      }
    }
  }

  .ladder-menu-container {
    width: 800px !important;
    margin-top: 10px;
    margin-bottom: 10px;

    .ladder-menu {
      background-color: rgba(42, 39, 39, 0.85) !important;
    }

    .active.item {
      background-color: #347b4f !important;
    }
  }

  table {
    th {
      padding: 3px !important;
      background-color: #155b80 !important;
    }

    tr {
      background-color: #202225;

      td {
        padding: 3px !important;
        color: #8a8e94;
      }
    }
  }
}