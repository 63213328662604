.div-live-server {
  min-height: 400px;

  table {
    th {
      padding: 3px !important;
      background-color: #155b80 !important;
    }

    tr {
      background-color: #202225;

      td {
        padding: 3px !important;
        color: #8a8e94;
      }
    }
  }

  .world-image {
    width: 215px !important;
  }

  span img {
    width: 16px !important;
    height: 16px !important;
    border-radius: 8px;
  }

  .span-name {
    padding-left: 5px;
  }

  .header {
    color: #d37f29 !important;
  }

  .refresh-label {
    background-color: #2d2e30 !important;

    label::before {
      background-color: #8a8e94 !important;
    }
  }

  .refresh-group {
    text-align: right;
    position: absolute;
    right: 2px;
    top: 2px;

    .refresh-checkbox {
      margin-bottom: 5px;
    }

    .refresh-label {
      background-color: #4b4f55 !important;
    }

    .icon-loading {
      color: yellow;
    }
  }
}