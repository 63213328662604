.ladder-weekly-news {

  .weekly-header-image {
    //max-width: 10px;
    //width: 90%;
    border: 2px solid #b18c12;
  }

  table {
    th {
      padding: 3px !important;
      background-color: #155b80 !important;
    }

    tr {
      background-color: #202225;

      td {
        padding: 3px !important;
        color: #8a8e94;
      }
    }
  }

  .div-header {
    margin-top: 10px;
    margin-bottom: 10px;

    .header {
      color: #d37f29 !important;

      .year-name {
        color: #29b8bf;
      }
    }

    .week-name {
      color: #6dbf2f;
    }
  }

  .extended-stats-button {
    margin-top: 12px !important;
  }
}