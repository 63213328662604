.ladder-extended-wrapper {

  .ladder-extended-menu-container {
    .div-list {
      padding-top: 10px;
      background-color: #2d2e30 !important;
    }

    .ladder-extended-menu-switcher {
      background-color: rgba(42, 39, 39, 0.85) !important;
      margin-bottom: 0;
    }

    .active.item {
      background-color: #347b4f !important;
    }

    .list {
      margin-right: 20px;
      margin-left: 20px;
      padding-bottom: 10px !important;

      .active.item {
        background-color: #224830 !important;
      }

      a::first-letter {
        color: #388b57;
      }
    }
  }

  .ladder-extended-table-container {

  }

  .ladder-extended-chart-container {
    .ladder-extended-chart {
      border: 3px solid #0e866c;
      border-radius: 5px;
      background-color: #D5D0CF;
    }
  }
}