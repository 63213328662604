.csp-daily-chart-wrapper {

  border: 3px solid #0e866c;
  border-radius: 5px;
  background-color: #D5D0CF;

  .highcharts-background {
    //fill: #2a2727;
    //color: white;
    //stroke: #a4edba;
    //stroke-width: 2px;
  }

  .daily-highcharts {
    //width: 20%;
    //min-width: 300px;
  }
}

