.game-history-stats-info {

  background-color: #202225;
  border-color: #202225;

  th {
    background-color: #155b80 !important;
  }

  tr {
    background-color: #202225;
    color: #8a8e94;
    td.commander {
      background-color: #0c504c;
    }
  }

  .cell-team-name {
    min-width: 150px;
    max-width: 150px;
  }
}
