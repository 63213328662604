@media only screen and (min-width: 1200px) {
  .csp-navigation {
    width: 100%;
    position: fixed;
    //border-bottom: 1px solid black;
    z-index: 999;

    .csp-menu {
      background-color: rgba(42, 39, 39, 0.85) !important;

      .active {
        background-color: #973716 !important;
      }
    }

    .menu-placeholder-small {
      min-width: 80px;
    }

    .menu-placeholder-large {
      min-width: 160px;
    }

    .logged-in-dropdown {
      float: right;
      background-color: #4e4e4e !important;

      .text {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      i {
        margin-left: 5px !important;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .csp-navigation {
    display: none;
  }
}