.game-history-stats {
  width: 100%;

  .grid-wrapper {
    margin: 0 1% 15px;
    color: white;

    .grid-column {
      //border: 1px solid yellow;
      //overflow:auto;
      //white-space: nowrap;

      .csp-history-stats-table {

        background-color: #202225;
        border-color: #202225;

        th {
          background-color: #155b80 !important;
        }

        tr {
          background-color: #202225;
          color: #8a8e94;
        }
      }
    }
  }

  .history-stats-menu-container {
    width: 800px !important;
    margin-top: 10px;
    //margin-bottom: -13px;

    .stats-menu {
      background-color: rgba(42, 39, 39, 0.85) !important;
    }

    .active.item {
      background-color: #347b4f !important;
    }

    .menu-item-stats {
      //border: 1px solid orange;
      //width: 300px;
    }
    .menu-item-accuracy {
      //border: 1px solid orange;
      //background-color: grey !important;
      //width: 300px;
    }
  }
}