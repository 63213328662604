.csp-online-wrapper {

  .online-widget {

    width: 270px;

    .online-users-list {
      overflow: auto;
      //max-height: 300px;
      //min-height: 100px;
      background-color: #202225 !important;
      background-blend-mode: saturation;
      background-repeat: no-repeat, no-repeat !important;
      background-position: 50% 100%, 100% 100% !important;
      //background-size: 410px 100%, 110px 130px !important;
      background-size: auto 100% !important;
      color: #8a8e94 !important;
      padding-top: 0;

      .list img {
        width: 16px;
        height: 16px;
        border-radius: 8px;
      }

      span {
        padding-left: 5px;
      }
    }

    .online-segment-top {
      background-color: #155b80 !important;

      .online-logo {
        filter: brightness(50%) sepia(100) saturate(100) hue-rotate(330deg);
        width: 36px;
        height: 36px;
      }
    }

    .segment-live-bottom {
      background-color: #202225;
      margin-top: -1px !important;

      .icon-loading {
        color: yellow;
      }
    }

    .name-without-icon {
      margin-left: 16px;
    }

    .customized-scrollbar::-webkit-scrollbar {
      width: 7px;
    }

    .segment-servers {
      padding-top: 25px;
      //padding-bottom: 2px;
      background-color: #202225;
      color: #acb2b3;

      overflow: auto;
      //max-height: 300px;
      //min-height: 100px;
      background-blend-mode: saturation;
      background-repeat: no-repeat, no-repeat !important;
      background-position: 50% 100%, 100% 100% !important;
      background-size: auto 100% !important;

      .grid:not(:first-child) {
        margin-top: 0;
      }

      .server-label {
        word-break: break-word;
        background-color: #000000 !important;
      }

      .server-label:hover {
        background-color: #244936 !important;
      }

      .online-label {
        background-color: #b7621f !important;

        .online-label-span-live {
          color: white !important;
        }

        .online-label-span-max {
          color: #323232 !important;
        }
      }

      .server-icon {
        margin-right: 2px;
      }

      .online-column {
        padding-left: 0 !important;
      }
    }

    border: 0 !important;
    box-shadow: none !important;
  }

  .selected-server {
    background-color: #07394e;
  }
}

