.csp-daily-members-wrapper {

  .daily-members-segment-group {

    .daily-members-segment-top {
      //background-color: #202225;
      background-color: #155b80;

      .daily-members-header {
        margin-bottom: 0;
      }
    }

    .daily-members-users-list-segment {
      overflow: auto;
      height: 641px;
      max-height: 641px;
      background-color: #202225 !important;
      color: #8a8e94 !important;

      background-size: auto 100% !important;
      background-blend-mode: saturation;
      background-repeat: no-repeat, no-repeat !important;
      background-position: bottom -250px right 50% !important;

      word-break: break-all;

      .list img {
        width: 16px;
        height: 16px;
        border-radius: 8px;
      }

      span {
        padding-left: 5px;
      }

      a.online-link-name {
        color: #8a8e94;
      }

      .name-without-icon {
        margin-left: 16px;
      }
    }

    .daily-members-segment-bottom {
      background-color: #202225;
    }

    .customized-scrollbar::-webkit-scrollbar {
      //width: 7px;
      background-color: #37393b;
    }
  }
}

