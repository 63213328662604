.csp-label {
  background-color: #b7621f !important;
  color: white !important;

  .detail {
    color: black !important;
  }
}

a.link-color {
  color: #8a8e94;
}

a.link-color:hover {
  color: #1e70bf !important;
}

a img {
  width: 16px !important;
  height: 16px !important;
  border-radius: 8px;
}

a span.span-name {
  padding-left: 5px;
}

@media only screen and (min-width: 1200px) {
  .app-container {
    padding-top: 50px;
  }
}

@media screen and (max-width: 1199px) {
  .app-container {
    padding-top: 10px;
  }
}

.app-container {
  display: flex;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}