.home-page-base-container {

  .segment {
    background-color: #2d2e30 !important;
    border: 2px solid #155b80 !important;
    color: #8a8e94 !important;

    .header {
      color: #d37f29;
    }
  }
}